var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TJDetailMenu"},_vm._l((_vm.dataList),function(item,index){return _c('div',{key:index,class:[
      {
        'topBorder': item.topBorder == true,
        'rightBorder': item.rightBorder == false,
        'bottomBorder': item.bottomBorder == false,
        'absorbance': item.type != null
      },
      'box' ],style:('width:' + item.width)},[_c('p',[_vm._v(_vm._s(item.name)+":")]),_c('p',[_vm._v(_vm._s(item.value == null ? '-' : item.value))])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }