
import request from '@/utils/request'
const api = {
    //合并质控品批号(临时使用)
    // merge_qc_serum(params) {
    //     return request({
    //         url: '/api/merge_qc_serum/',
    //         method: 'get',
    //         params
    //     })
    // },

    //质控面板
    qc_panel(params) {
        return request({
            url: '/api/qc_panel/',
            method: 'get',
            params
        })
    },
    // 根据机号查询指定机号下的所有质控品
    serum_data_list(params) {
        return request({
            url: '/api/serum_data_list/',
            method: 'get',
            params
        })
    },
    //各省份下对应的云质控医院
    qc_hospital_list(params) {
        return request({
            url: '/api/qc_hospital_list/',
            method: 'get',
            params
        })
    },

    //云质控
    qcpanel(params) {
        return request({
            url: '/api/qc_panel_province/',
            method: 'get',
            params
        })
    },
    // 质控面板(医院)
    qc_panel_hospital(params) {
        return request({
            url: '/api/qc_panel_hospital/',
            method: 'get',
            params
        })
    },
    // 查询默认批号或检测项目
    default_qc_setting(params) {
        return request({
            url: '/api/default_qc_setting/',
            method: 'get',
            params
        })
    },
    // 新增质控品第一步获取机号
    qc_hosipital_instruments(params) {
        return request({
            url: '/api/instruments/',
            method: 'get',
            params
        })
    },

    // 质控品定靶结束时间消息通知
    target_endtime_message(params) {
        return request({
            url: '/api/target_endtime_message/',
            method: 'get',
            params
        })
    },
    // 室间质控
    room_qc_serum(params) {
        return request({
            url: '/api/room_qc_serum/',
            method: 'get',
            params
        })
    },
    // 室间质控:质控表格生成
    // room_qc_serum_list(params) {
    //     return request({
    //         url: '/api/room_qc_serum_list/',
    //         method: 'get',
    //         params
    //     })
    // },

    // 统计当天的失控数据
    // daily_lc_data(params) {
    //     return request({
    //         url: '/api/daily_lc_data/',
    //         method: 'get',
    //         params
    //     })
    // },
    // 室内质控-基本信息(质量控制图)
    qc_hospital_info(params) {
        return request({
            url: '/api/qc_hospital_info/',
            method: 'get',
            params
        })
    },
    // 新增质控品
    create_qc_serum(data) {
        return request({
            url: '/api/create_qc_serum/',
            method: 'post',
            data
        })
    },
    // 设置默认机号，项目，批号
    set_default_qc_bain(data) {
        return request({
            url: '/api/set_default_qc_bain/',
            method: 'post',
            data
        })
    },
    // 获取检测项目和机号对应的质控批号
    qc_batch_list(params) {
        return request({
            url: '/api/qc_batch_list/',
            method: 'get',
            params
        })
    },
    // 失控规则数据字典
    qc_rules_dict(params) {
        return request({
            url: '/api/qc_rules_dict/',
            method: 'get',
            params
        })
    },
    // 失控规则数据
    qc_rule_list(params) {
        return request({
            url: '/api/qc_rule_list/',
            method: 'get',
            params
        })
    },
    // 设置质控规则
    set_qc_rule(data) {
        return request({
            url: '/api/set_qc_rule/',
            method: 'post',
            data
        })
    },
    // 质量控制图-获取质控数据
    // qc_data(params) {
    //     return request({
    //         url: '/api/qc_data/',
    //         method: 'get',
    //         params
    //     })
    // },
    // 室内质控-质控数据根据时间区间过滤
    screen_qc_data(params) {
        return request({
            url: '/api/screen_qc_data/',
            method: 'get',
            params
        })
    },
    // 室内质控-质控数据根据时间区间过滤 -- 新
    screen_qc_consistency(params) {
        return request({
            url: '/api/screen_qc_consistency/',
            method: 'get',
            params
        })
    },
    // 质量控制图-设置均值
    set_qc_target(data) {
        return request({
            url: '/api/set_qc_target/',
            method: 'post',
            data
        })
    },
    // 室内质控-查找所有省份
    city(params) {
        return request({
            url: '/api/city/',
            method: 'get',
            params
        })
    },
    // 室内质控-均值管理
    qc_target(params) {
        return request({
            url: '/api/qc_target/',
            method: 'get',
            params
        })
    },
    // 均值管理-设置质控规则和质控点
    // update_target_rules(data) {
    //     return request({
    //         url: '/api/update_target_rules/',
    //         method: 'post',
    //         data
    //     })
    // },
    // 剔除定靶
    // del_is_dq(data) {
    //     return request({
    //         url: '/api/del_is_dq/',
    //         method: 'post',
    //         data
    //     })
    // },
    // 质量控制图--设置质控号
    qc_number_set_up(data) {
        return request({
            url: '/api/qc_number_set_up/',
            method: 'post',
            data
        })
    },
    //室内质控-填写失控报告 获取数据
    report_details(params) {
        return request({
            url: '/api/report_details/',
            method: 'get',
            params
        })
    },
    //室内质控-获取失控原因以及对应措施
    analysis_data(params) {
        return request({
            url: '/api/analysis_data/',
            method: 'get',
            params
        })
    },

    // 合并或者拆分质控数据 旧
    // merge_or_split(data) {
    //     return request({
    //         url: '/api/merge_or_split/',
    //         method: 'post',
    //         data
    //     })
    // },

    // 合并或者拆分质控数据 新2023.04.17(回退/设置默认质控点(主要用于回退设置质控默认点))
    // set_qc_instance2point(data) {
    //     return request({
    //         url: '/api/set_qc_instance2point/',
    //         method: 'post',
    //         data
    //     })
    // },

    // 获取合并后的原始质控实例
    // qc_instance_control(params) {
    //     return request({
    //         url: '/api/qc_instance_control/',
    //         method: 'get',
    //         params
    //     })
    // },

    // 设置默认参与计算的质控点
    // default_qc_instance(data) {
    //     return request({
    //         url: '/api/default_qc_instance/',
    //         method: 'post',
    //         data
    //     })
    // },

    // 填写失控报告-新增失控原因分析和失控信息
    create_qc_report(data) {
        return request({
            url: '/api/create_qc_report/',
            method: 'post',
            data
        })
    },
    // 生成失控报告PDF
    // qc_report_pdf(params) {
    //     return request({
    //         url: '/api/qc_report_pdf/',
    //         method: 'get',
    //         params
    //     })
    // },

    // 室间一致性评价
    qc_hospital_info(params) {
        return request({
            url: '/api/qc_hospital_info/',
            method: 'get',
            params
        })
    },


    // // 删除质控点
    // delete_qc_point(data) {
    //     return request({
    //         url: '/api/delete_qc_point/',
    //         method: 'post',
    //         data
    //     })
    // },
    // // 修改失控数据是否参与计算
    // update_qc_serum(data) {
    //     return request({
    //         url: '/api/update_qc_serum/',
    //         method: 'post',
    //         data
    //     })
    // },
    // 获取医院对应的质控信息
    qc_serum_info(params) {
        return request({
            url: '/api/qc_serum_info/',
            method: 'get',
            params
        })
    },
    // 删除质控品
    delete_qc_serum(data) {
        return request({
            url: '/api/delete_qc_serum/',
            method: 'post',
            data
        })
    },
    // 手动挑选质控点
    // qc_instance_pick(data) {
    //     return request({
    //         url: '/api/qc_instance_pick/',
    //         method: 'post',
    //         data
    //     })
    // },
    // 获取所有医院等级
    hospital_level() {
        return request({
            url: '/api/hospital_level/',
            method: 'get',
        })
    },

    // 室间一致性评价
    qc_consistency(params) {
        return request({
            url: '/api/qc_consistency/',
            method: 'get',
            params
        })
    },

    // 根据qc_instance_id查看原始数据
    search_qc_instance(params) {
        return request({
            url: '/api/search_qc_instance/',
            method: 'get',
            params
        })
    },

    // 修改 是否接受 
    qc_point_accept(data) {
        return request({
            url: '/api/qc_point_accept/',
            method: 'post',
            data
        })
    },
    // 获取质控品累计值
    qc_stats(data) {
        return request({
            url: '/api/qc_stats/',
            method: 'post',
            data
        })
    },
    // 查询质控月度报告
    qc_month_report(params) {
        return request({
            url: '/api/qc_month_report/',
            method: 'get',
            params
        })
    },
    // 获取医联体数据字典
    medical_consortia_list(params) {
        return request({
            url: '/api/medical_consortia_list/',
            method: 'get',
            params
        })
    },
}
export default api
